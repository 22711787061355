<template>
  <div class="slide">
    <div class="slide__preview-container" v-if="slide.type === 'image'">
      <div
        class="slide__preview"
        v-if="!isOnlyPortrait"
        :style="{
          'background-image': 'url(' + slide.url.image.landscape + ')'
        }"
      ></div>
      <div
        class="slide__preview--portrait"
        v-else
        :style="{ 'background-image': 'url(' + slide.url.image.portrait + ')' }"
      ></div>
    </div>

    <div class="slide__preview-container" v-if="slide.type === 'video'">
      <div class="slide__preview" v-if="!isOnlyPortrait">
        <video
          class="slide__preview-video"
          :src="slide.url.video.landscape"
        ></video>
      </div>

      <div class="slide__preview--portrait" v-else>
        <video
          class="slide__preview-video"
          :src="slide.url.video.portrait"
        ></video>
      </div>
    </div>

    <div class="slide__preview-container" v-if="slide.type === 'template'">
      <div
        class="slide__preview slide__preview--template"
        v-if="!slide.preview"
      ></div>
      <div
        class="slide__preview slide__preview--template"
        v-else
        :style="{
          'background-image': 'url(' + slide.preview + ')'
        }"
      ></div>
    </div>

    <div class="slide__preview-container" v-if="slide.type === 'webview'">
      <div class="slide__preview slide__preview--webview"></div>
    </div>

    <div class="slide__preview-container" v-if="slide.type === 'wallsio'">
      <div class="slide__preview slide__preview--wallsio"></div>
    </div>

    <div class="slide__preview-container" v-if="isWidget">
      <div
        class="slide__preview slide__preview--widget"
        :style="{
          'background-image': 'url(' + slide.preview + ')'
        }"
      ></div>
    </div>

    <div class="slide__title">
      <inline-svg
        v-if="slide.type === 'video'"
        class="slide__type-icon"
        :src="require('../assets/icons/SlideType_video.svg')"
        aria-label="Video"
      ></inline-svg>
      <inline-svg
        v-if="slide.type === 'template'"
        class="slide__type-icon"
        :src="require('../assets/icons/SlideType_template.svg')"
        aria-label="Templates"
      ></inline-svg>
      <inline-svg
        v-if="slide.type === 'image'"
        class="slide__type-icon"
        :src="require('../assets/icons/SlideType_image.svg')"
        aria-label="Image"
      ></inline-svg>
      <inline-svg
        v-if="slide.type === 'webview'"
        class="slide__type-icon"
        :src="require('../assets/icons/SlideType_webview.svg')"
        aria-label="Webview"
      ></inline-svg>
      <inline-svg
        v-if="slide.type === 'wallsio'"
        class="slide__type-icon"
        :src="require('../assets/icons/SlideType_wallsio.svg')"
        aria-label="Wallsio"
      ></inline-svg>
      {{ slide.title }}
    </div>
    <div class="slide__date" v-if="dateStart && dateEnd">
      {{ dateStart }} - {{ dateEnd }}
    </div>

    <div
      v-if="slide.frequency > 0 && slide.status === 'published'"
      class="slide__highlight"
    >
      <inline-svg
        class="slide__frequency-icon"
        :src="require(`../assets/icons/${frequencyIcon}.svg`)"
        aria-label="Frequency Icon"
      ></inline-svg>
    </div>

    <div class="slide__status" v-if="slide.status !== 'published'">
      {{ $t(`components.slide.${slide.status}`) }}
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  props: {
    slide: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    dateStart() {
      if (this.slide.dates.start) {
        return dayjs(this.slide.dates.start).format("D.MMM");
      }

      return null;
    },
    dateEnd() {
      if (this.slide.dates.end) {
        return dayjs(this.slide.dates.end).format("D.MMM");
      }

      return null;
    },
    isOnlyPortrait() {
      if (
        !this.slide.url[this.slide.type].landscape &&
        this.slide.url[this.slide.type].portrait
      ) {
        return true;
      }

      return false;
    },
    frequencyIcon() {
      if (this.slide.frequency === 1) {
        return "2nd";
      } else if (this.slide.frequency === 2) {
        return "3rd";
      } else if (this.slide.frequency === 4) {
        return "5th";
      } else if (this.slide.frequency === 9) {
        return "10th";
      } else {
        return "";
      }
    },
    isWidget() {
      return !["image", "video", "template", "webview", "wallsio"].includes(
        this.slide.type
      );
    }
  }
};
</script>

<style lang="scss">
.slide {
  @apply w-full shadow-ls-shadow p-4 flex flex-col bg-white rounded mb-4 relative;

  &__preview-container {
    position: relative;
    margin-bottom: 16px;
  }

  &__preview {
    padding-top: 56.25%;
    width: 100%;
    @apply bg-cover;

    &--template {
      background-image: url("../assets/img/template_placeholder.gif");
    }

    &--webview {
      background-image: url("../assets/img/webview-thumbnail.png");
      object-fit: contain;
    }

    &--wallsio {
      background-image: url("../assets/img/wallsio-thumbnail.png");
      object-fit: contain;
    }

    &--widget {
      object-fit: contain;
    }

    &-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__preview--portrait {
    width: 100%;
    padding-top: 177.78%;
    @apply mb-2 bg-cover;

    .slide__preview-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__title {
    font-size: 12px;
    line-height: 14px;
    color: #000;
    padding-top: 2px;
    width: 205px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    margin-top: 8px;
  }

  &__date {
    font-size: 12px;
    line-height: 14px;
    @apply text-ls-gray-800 mt-2;
  }

  &__type-icon {
    width: 16px;
    height: 16px;
    margin-top: -2px;
    display: inline-block;
  }
  &__status {
    @apply absolute text-white bg-gray-400;
    width: auto;
    bottom: 16px;
    right: 16px;
    border-radius: 4px;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-weight: 600;
    padding: 2px 4px;
    -webkit-font-smoothing: antialiased;
  }

  &__frequency-icon {
    @apply absolute;
    bottom: 16px;
    right: 16px;
  }
}

@media screen and (min-width: 900px) {
  .slide {
    height: 195px;

    &__preview {
      width: 205px;
    }

    &__preview-container {
      position: relative;
      margin-bottom: 0;
    }

    &__preview--portrait {
      width: 63px;
      height: 115px;
      margin: 0 64px;
      padding-top: 0;
      @apply bg-cover;
    }
  }
}
</style>
