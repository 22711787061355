export const settingsAdmin = [
  {
    name: "Account",
    link: "/settings/account"
  },
  {
    name: "Team",
    link: "/settings/team"
  },
  {
    name: "Linked Teams",
    link: "/settings/linked-teams"
  },
  // {
  //   name: "Billing",
  //   link: "/settings/billing"
  // },
  {
    name: "Logout",
    link: "logout"
  }
];

export const settingsEditor = [
  {
    name: "Account",
    link: "/settings/account"
  },
  {
    name: "Logout",
    link: "logout"
  }
];

export const slideLinks = [
  {
    name: "active",
    link: "/slides/active",
    icon: "Slides"
  },
  {
    name: "drafts",
    link: "/slides/drafts",
    icon: "Draft"
  },
  {
    name: "archive",
    link: "/slides/archive",
    icon: "Archive"
  }
];

export const slideLinksWithoutIcons = [
  {
    name: "active",
    link: "/slides/active"
  },
  {
    name: "drafts",
    link: "/slides/drafts"
  },
  {
    name: "archive",
    link: "/slides/archive"
  }
];

export const templateLinks = [
  {
    name: "orga",
    link: "/templates/organization"
  },
  {
    name: "archive",
    link: "/templates/archive"
  }
];

export const brand = [
  {
    name: "Logo",
    link: "/brandmanager/logo"
  },
  {
    name: "Colors",
    link: "/brandmanager/colors"
  },
  {
    name: "Typography",
    link: "/brandmanager/typography"
  },
  {
    name: "Fonts",
    link: "/brandmanager/fonts"
  }
  // {
  //   name: "Background Images",
  //   link: "/brand"
  // },
  // {
  //   name: "Templates",
  //   link: "/brand"
  // }
];

export const nav = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: "Home"
  },
  {
    name: "Slides",
    link: "/slides/active",
    icon: "Slides"
  },
  {
    name: "Screens",
    link: "/screens",
    icon: "Landscape"
  },
  {
    name: "Brand Manager",
    link: "/brandmanager/logo",
    icon: "BrandManager"
  },
  {
    name: "Templates",
    link: "/templates/organization",
    icon: "Templates"
  }
];

export const navMobile = [
  {
    name: "Dashboard",
    link: "/dashboard",
    icon: "Home"
  },
  {
    name: "Slides",
    link: "/slides/active",
    icon: "Slides"
  },
  {
    name: "Screens",
    link: "/screens",
    icon: "Landscape"
  },
  {
    name: "Templates",
    link: "/templates/organization",
    icon: "Templates"
  }
];

export const enterpriseNav = [
  {
    name: "Branches",
    link: "/dashboard"
  },
  {
    name: "Campains",
    link: "/campaigns"
  },
  {
    name: "Brand",
    link: "/brand"
  }
];

export const branchNav = [
  {
    name: "Slides",
    link: "/dashboard"
  },
  {
    name: "Campains",
    link: "/campaigns"
  },
  {
    name: "Brand",
    link: "/brand"
  }
];
