<template>
  <div
    class="nav-bar--mobile"
    :class="{ 'nav-bar--open': showNavigation || showTeams }"
  >
    <div class="nav-bar--mobile__header">
      <div class="nav-bar--mobile__button--navigation">
        <inline-svg
          class="nav-bar--mobile__button-icon"
          v-if="!showNavigation && !showTeams"
          :src="require(`../assets/icons/Drawer.svg`)"
          @click="showNavigation = true"
        ></inline-svg>
        <inline-svg
          class="nav-bar--mobile__button-icon"
          v-if="showNavigation || showTeams"
          @click="closeNavBar"
          :src="require(`../assets/icons/X.svg`)"
        ></inline-svg>
      </div>
      <div class="nav-bar--mobile__logo" v-if="!showNavigation && !showTeams">
        <inline-svg
          class="nav-bar--mobile__link-icon"
          :src="require(`../assets/icons/LobbySpace.svg`)"
        ></inline-svg>
      </div>
      <div
        class="nav-bar--mobile__button--teams"
        v-if="!showTeams && !showNavigation"
        @click="showTeams = true"
      >
        <inline-svg
          class="nav-bar--mobile__button-icon"
          :src="require(`../assets/icons/Home.svg`)"
        ></inline-svg>
      </div>
    </div>
    <div
      class="nav-bar--mobile__content nav-bar--mobile__links"
      v-if="showNavigation"
    >
      <div
        class="nav-bar--mobile__item"
        :class="{
          'nav-bar--mobile__item--active': $route.path === '/' + link.link
        }"
        v-for="(link, linkIndex) in links"
        :key="linkIndex"
        @click="closeNavBar"
      >
        <router-link :to="link.link" class="nav-bar--mobile__link">
          <inline-svg
            class="nav-bar--mobile__link-icon"
            v-if="link.icon"
            :src="require(`../assets/icons/${link.icon}.svg`)"
            :aria-label="link.icon"
          ></inline-svg>
          {{ link.name }}
        </router-link>
      </div>
      <div class="nav-bar--mobile__divider"></div>
      <div class="nav-bar__footer">
        <div
          class="nav-bar__item"
          :class="{ 'nav-bar__item--active': $route.name === 'Settings' }"
          @click="closeNavBar"
        >
          <router-link to="/settings/account" class="nav-bar__link">
            <inline-svg
              class="nav-bar__link-icon"
              :src="require(`../assets/icons/Settings.svg`)"
              aria-label="Settings"
            ></inline-svg>
            Settings
          </router-link>
        </div>

        <div
          class="nav-bar__item"
          :class="{ 'nav-bar__item--active': $route.name === 'Support' }"
          @click="closeNavBar"
        >
          <router-link to="/support" class="nav-bar__link">
            <inline-svg
              class="nav-bar__link-icon"
              :src="require(`../assets/icons/message-circle.svg`)"
              aria-label="Settings"
            ></inline-svg>
            Support
          </router-link>
        </div>

        <a
          class="nav-bar__link"
          href="https://support.lobbyspace.me/"
          target="_blank"
          @click="closeNavBar"
        >
          <inline-svg
            class="nav-bar__link-icon"
            :src="require(`../assets/icons/zap.svg`)"
            aria-label="KnowledgeBase"
          ></inline-svg>
          Knowledge Base
        </a>
      </div>
    </div>

    <div
      class="nav-bar--mobile__content nav-bar--mobile__teams"
      v-if="showTeams"
    >
      <div
        class="nav-bar--mobile__teams__item"
        v-for="(team, teamIndex) in teams"
        :key="teamIndex"
        @click="chooseTeam(team)"
      >
        <div class="nav-bar--mobile__teams__name">{{ team.name }}</div>
        <div
          class="nav-bar--mobile__teams__divider"
          v-if="teamIndex < teams.length - 1"
        ></div>
      </div>
      <div class="nav-bar--mobile__teams__logout" @click="onLogout">
        <inline-svg
          class="nav-bar--mobile__teams__logout-icon"
          :src="require(`../assets/icons/Logout.svg`)"
          aria-label="Down"
        ></inline-svg>
        {{ $t("components.teamDropdown.logout") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { navMobile } from "@/schema/sideBar";

export default {
  data: () => ({
    showNavigation: false,
    showTeams: false
  }),
  computed: {
    ...mapGetters("teams", {
      teams: "getTeams"
    }),
    links() {
      return navMobile;
    }
  },
  methods: {
    ...mapActions("teams", ["setCurrentTeam"]),
    ...mapActions("user", ["logout"]),
    closeNavBar() {
      this.showNavigation = false;
      this.showTeams = false;
    },
    chooseTeam(team) {
      this.setCurrentTeam(team.id);
      this.closeNavBar();
      window.location.reload();
    },
    onLogout() {
      this.logout();
    }
  }
};
</script>

<style lang="scss">
.nav-bar--mobile {
  @apply fixed left-0 top-0 bg-white w-screen z-10;

  &--open {
    height: 100vh;
  }

  &__header {
    padding: 10px 12px;
    @apply border-b border-ls-gray-200 flex items-center justify-between;
  }

  &__button--navigation {
    border: 1px solid #d0d0d2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px 14px;
  }

  &__button--teams {
    border: 1px solid #d0d0d2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px 14px;
  }

  &__links {
    padding: 14px 12px;
  }

  &__divider {
    height: 1px;
    @apply bg-ls-gray-200 w-full my-2;
  }

  &__item,
  &__footer {
    @apply p-3 rounded-lg font-semibold text-ls-gray-800 text-xs;
  }

  &__item {
    .nav-bar--mobile__link-icon {
      path,
      rect {
        stroke: #7b7b7c;
      }

      path.templates {
        stroke: none;
      }

      path.play-button,
      path.brand-manager,
      path.templates {
        fill: #7b7b7c;
      }
    }

    &--active {
      @apply bg-ls-purple text-white;

      .nav-bar--mobile__link-icon {
        path,
        rect {
          stroke: #fff;
        }

        path.templates {
          fill: #fff;
        }

        path.play-button,
        path.brand-manager,
        path.settings {
          fill: #fff;
        }

        path.message {
          fill: #6f81e0;
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;

    &-icon {
      margin-right: 8px;
    }
  }

  &__footer {
    @apply flex flex-col;

    .nav-bar--mobile__link-icon {
      path {
        fill: #7b7b7c;
      }
    }

    &--active {
      @apply bg-ls-purple text-white;

      .nav-bar--mobile__link-icon {
        path {
          fill: #fff;
        }
      }
    }
  }

  &__teams {
    padding: 16px 8px;

    &__item {
      @apply px-2;
    }

    &__name {
      @apply font-semibold text-black text-xs py-3 px-2;
    }

    &__divider {
      height: 1px;
      @apply bg-ls-gray-200 w-full;
    }

    &__logout {
      @apply border-t border-ls-gray-200 flex text-ls-gray-800 text-xs font-semibold p-4;

      &-icon {
        margin-right: 8px;

        path {
          fill: #7b7b7c;
        }
      }
    }
  }
}
</style>
