<template>
  <div class="template">
    <div class="template__preview-container" @click="onCreate">
      <div
        class="template__preview template__preview--template"
        v-if="!template.preview || template.updateScreenshot === true"
      ></div>
      <div
        class="template__preview template__preview--template"
        v-else
        :style="{
          'background-image': 'url(' + template.preview + ')'
        }"
      ></div>
    </div>
    <div class="template__title">{{ template.name }}</div>

    <div
      class="template__menu"
      v-if="!isParent && !isRoot && !isLobbyspace && showTemplateButton"
    >
      <inline-svg
        :src="require(`../assets/icons/More.svg`)"
        aria-label="More Icon"
        @click="showMenu = true"
        class="template__menu-btn"
      ></inline-svg>

      <div class="template__menu-wrapper" v-if="showMenu">
        <div
          class="template__menu-option"
          @click="onEdit"
          v-if="status !== 'archived' && status !== 'deleted'"
        >
          {{ $t("components.template.edit") }}
        </div>
        <div
          class="template__menu-option"
          @click="showChangeModal('duplicate')"
          v-if="status !== 'archived' && status !== 'deleted'"
        >
          {{ $t("components.template.duplicate") }}
        </div>
        <div
          class="template__menu-option"
          @click="showChangeModal('archive')"
          v-if="status !== 'archived' && status !== 'deleted'"
        >
          {{ $t("components.template.archive") }}
        </div>
        <div
          class="template__menu-option"
          @click="showChangeModal('activate')"
          v-if="status === 'archived'"
        >
          {{ $t("components.template.activate") }}
        </div>
        <div class="template__menu-option" @click="showChangeModal('delete')">
          {{ $t("components.template.delete") }}
        </div>
      </div>
      <div
        class="template__menu-backdrop"
        v-if="showMenu"
        @click="showMenu = false"
      ></div>
    </div>

    <div class="template__menu" v-if="isLobbyspace && showTemplateButton">
      <inline-svg
        :src="require(`../assets/icons/More.svg`)"
        aria-label="More Icon"
        @click="showImportMenu = true"
        class="template__menu-btn"
      ></inline-svg>

      <div class="template__menu-wrapper" v-if="showImportMenu">
        <div class="template__menu-option" @click="openImportModal">
          {{ $t("components.template.import") }}
        </div>
      </div>
      <div
        class="template__menu-backdrop"
        v-if="showImportMenu"
        @click="showImportMenu = false"
      ></div>
    </div>

    <Modal
      v-if="showChangeConfirmModal"
      @close="onChangeModalClose"
      class="template__change-modal"
      :heading="$t(`components.template.${changeType}Modal.title`)"
    >
      <template #modal-content>
        <div class="template__change-modal-content">
          {{ $t(`components.template.${changeType}Modal.content`) }}
        </div>
        <div class="template__change-modal-buttons">
          <Button
            theme="secondary"
            class="template__change-modal-button"
            @click="onChangeModalClose"
          >
            {{ $t(`components.template.${changeType}Modal.cancelBtn`) }}
          </Button>
          <Button
            v-if="changeType !== 'delete'"
            class="template__change-modal-button"
            @click="onChange"
          >
            {{ $t(`components.template.${changeType}Modal.confirmBtn`) }}
          </Button>
          <Button
            v-else
            theme="warning"
            class="template__change-modal-button"
            @click="onChange"
          >
            {{ $t(`components.template.${changeType}Modal.confirmBtn`) }}
          </Button>
        </div>
      </template>
    </Modal>

    <Modal
      v-if="showImportModal"
      @close="showImportModal = false"
      class="template__import-modal"
      :heading="$t(`components.template.importModal.title`)"
    >
      <template #modal-content>
        <div class="template__import-modal-content">
          {{ $t(`components.template.importModal.content`) }}
        </div>
        <div class="template__import-modal-buttons">
          <Button
            theme="secondary"
            class="template__import-modal-button"
            @click="showImportModal = false"
          >
            {{ $t(`components.template.importModal.cancelBtn`) }}
          </Button>
          <Button
            v-if="changeType !== 'delete'"
            class="template__import-modal-button"
            @click="onImport"
          >
            {{ $t(`components.template.importModal.confirmBtn`) }}
          </Button>
        </div>
      </template>
    </Modal>

    <Modal
      v-if="showBrandGenerationModal"
      class="template__brand-generation-modal"
      :showCloseButton="false"
    >
      <template #modal-content>
        <div class="template__brand-generation-modal-content">
          <img
            :src="require(`../assets/icons/removebg-progress.gif`)"
            aria-label="progress"
          />
          <h2>{{ $t(`components.template.brandGenerationModal.title`) }}</h2>
          <p>{{ $t(`components.template.brandGenerationModal.text`) }}</p>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { create_UUID } from "../utils/utils";
import Modal from "@/components/Modal";
import Button from "@/components/Button";

export default {
  components: {
    Modal,
    Button
  },
  props: {
    template: {
      type: Object,
      default: () => {}
    },
    isParent: {
      type: Boolean,
      default: false
    },
    isRoot: {
      type: Boolean,
      default: false
    },
    isLobbyspace: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    teamId: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: "active"
    }
  },
  data: () => ({
    showMenu: false,
    showImportMenu: false,
    showImportModal: false,
    showBrandGenerationModal: false,
    showChangeConfirmModal: false,
    changeType: ""
  }),
  computed: {
    ...mapGetters("teams", {
      team: "getCurrentTeam",
      parentTeam: "getParentTeam",
      childSettings: "getChildSettings"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    showTemplateButton() {
      if (this.team && this.team.users) {
        if (this.parentTeam) {
          return (
            this.childSettings?.createTemplates &&
            this.team.users[this.user.user_id].role !== "editor"
          );
        }

        return this.team.users[this.user.user_id].role !== "editor";
      }

      return false;
    }
  },
  methods: {
    ...mapActions("slides", ["setOriginPage"]),
    ...mapActions("templates", [
      "duplicateTemplate",
      "importTemplate",
      "changeTemplateStatus"
    ]),
    onCreate() {
      if (this.$route.params.id === "archive") return;

      this.setOriginPage(this.$route.path);
      if (this.isParent) {
        this.goToEditorNew("1");
      } else if (this.isRoot) {
        this.goToEditorNew("2");
      } else if (this.isLobbyspace) {
        this.showBrandGenerationModal = true;

        setTimeout(() => {
          this.goToEditorNew("3");
        }, 2500);
      } else {
        this.goToEditorNew("0");
      }
    },
    goToEditorNew(isParent) {
      this.$router.push({
        name: "EditorNew",
        params: {
          file: "template",
          templateId: this.id,
          isParent: isParent
        }
      });
    },
    onEdit() {
      window.location.href = `/designer/?id=${this.teamId}&artboardId=${this.id}`;
    },
    openImportModal() {
      this.showImportMenu = false;
      this.showImportModal = true;
    },
    showChangeModal(type) {
      this.changeType = type;
      this.showMenu = false;
      this.showChangeConfirmModal = true;
    },
    onDuplicate() {
      const currentDate = new Date().toISOString();
      this.duplicateTemplate({
        teamId: this.teamId,
        templateId: create_UUID(),
        templateData: {
          ...this.template,
          name: this.template.name + this.$t("components.template.copy"),
          originalDate: currentDate,
          lastChangedDate: currentDate
        }
      });

      this.onChangeModalClose();
    },
    onImport() {
      const currentDate = new Date().toISOString();
      this.importTemplate({
        teamId: this.teamId,
        templateId: create_UUID(),
        templateData: {
          ...this.template,
          originalDate: currentDate,
          lastChangedDate: currentDate,
          updateScreenshot: true
        }
      });

      this.showImportModal = false;
    },
    onChange() {
      if (this.changeType === "duplicate") {
        this.onDuplicate();
        return;
      }

      let newStatus = "";

      if (this.changeType === "archive") {
        newStatus = "archived";
      } else if (this.changeType === "activate") {
        newStatus = "activated";
      } else if (this.changeType === "delete") {
        newStatus = "deleted";
      }

      this.changeTemplateStatus({
        teamId: this.teamId,
        id: this.id,
        template: {
          ...this.template,
          status: newStatus
        }
      });

      this.onChangeModalClose();
    },
    onChangeModalClose() {
      this.showChangeConfirmModal = false;
      this.changeType = "";
    }
  }
};
</script>

<style lang="scss">
.template {
  @apply shadow-ls-shadow p-4 flex flex-col bg-white rounded mb-4 relative;

  &__preview-container {
    position: relative;
  }

  &__preview {
    height: 200px;
    width: 100%;
    @apply mb-2 bg-cover;

    &--template {
      background-image: url("../assets/img/template_placeholder.gif");
    }
  }

  &__title {
    font-size: 12px;
    line-height: 14px;
    color: #000;
    width: 205px;
    padding-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  &__menu {
    position: absolute;
    right: 12px;
    bottom: 12px;
    padding: 4px;
    @apply cursor-pointer;

    &:hover {
      @apply bg-ls-gray-200;
      border-radius: 4px;
    }

    &-wrapper {
      @apply absolute bg-white  rounded shadow-md z-10 border border-ls-gray-100;
      right: 0;
      white-space: nowrap;
    }

    &-option {
      @apply text-xs font-semibold text-ls-gray-800 py-2 px-3;

      &:hover {
        @apply bg-ls-gray-100;
      }
    }

    &-backdrop {
      @apply fixed top-0 left-0 w-full h-full;
      z-index: 9;
      cursor: default;
    }
  }
  &__date {
    font-size: 12px;
    line-height: 14px;
    @apply text-ls-gray-800 mt-1;
  }

  &__type-icon {
    @apply absolute top-0 right-0 bg-gray-200 p-1 opacity-75;
    width: 24px;
    height: 24px;
  }

  &__highlight-icon {
    @apply absolute text-yellow-500;
    width: 16px;
    height: 16px;
    bottom: 16px;
    right: 16px;
  }

  &__change-modal,
  &__import-modal {
    &-buttons {
      @apply flex justify-between mt-4;
    }

    &-button {
      @apply w-full;

      &:first-child {
        @apply mr-4;
      }
    }
  }

  &__brand-generation-modal {
    &-content {
      @apply flex flex-col items-center justify-center;

      img {
        width: 36px;
        height: 36px;
        margin-bottom: 16px;
      }

      h2 {
        font-weight: 600;
      }

      p {
        font-size: 14px;
      }
    }
  }
}

@media screen and (min-width: 900px) {
  .template {
    height: 168px;

    &__preview {
      height: 112px;
      width: 205px;
      @apply mb-2;
    }

    &__preview--portrait {
      width: 77px;
      height: 112px;
      @apply mb-2 bg-cover;
    }
  }
}
</style>
